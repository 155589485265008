import {
  Box,
  Button,
  Fab,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router';
import { ONLINE_MEDIA_CLIPPING } from 'src/route';
import { useAppSelector } from 'src/app/hooks';
import useDownload from 'src/services/useDownload';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { useMemo, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useQueryUrl from 'src/hooks/useQuery';
import useOnlineMedia from 'src/services/useOnlineMedia';
import { useAlert } from 'src/hooks/useAlert';
import { generateWhatsappReportDoc } from 'src/utils/generateWhatsappReportDoc';
import { Packer } from 'docx';
import saveAs from 'file-saver';
import { generateArticleBySentimentDoc } from 'src/utils/generateArticleBySentimentDoc';
import { generateOfmDoc } from 'src/utils/generateOfmDoc';
import { generateOnmDoc } from 'src/utils/generateOnmDoc';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderOnlineMediaClippingById() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];
  const clippingList = useAppSelector(
    (state) => state.storeOnlineMediaStream.clippingList.data
  );
  const { handleClickAlert } = useAlert();
  const query = useQueryUrl();
  const tabClipping = query.get('tab');

  const clippingName =
    clippingList.result.length > 0 && id.length > 0
      ? clippingList.result.find((clipping) => clipping.id === id)?.name
      : '';

  const { downloadCsvMediaOnline } = useDownload();
  const { downloadClusteringCsv } = useOnlineMedia();
  const projectId = useAppSelector((state) => state.users.project.id);
  const advanceSearchOnlineMedia = useAppSelector(
    (state) => state.storeAdvanceSearchOnlineMedia
  );
  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );
  const articles = useAppSelector(
    (state) => state.storeOnlineMediaStream.articleList
  );

  const clusteringConfig = useAppSelector(
    (state) => state.storeOnlineMediaStream.clusteringConfig
  );

  const fillAdvanceSearchOnlineMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchOnlineMedia);
  }, [advanceSearchOnlineMedia]);

  const { languageSelected } = useAppSelector(
    (state) => state.storeOnlineMediaStream
  );

  const labels = useAppSelector((state) => state.storeLabel.labelArticle.data);

  const payloads = {
    projectId,
    timezone: 7,
    start: dateRangeFormatted.start,
    end: dateRangeFormatted.end,
    granularity: {
      unit: 'day',
      value: 1
    },
    ...fillAdvanceSearchOnlineMedia,
    clippingId: id,
    labelList: fillAdvanceSearchOnlineMedia.labelList.map(
      (label) => label.id || label
    ),
    languageList: languageSelected === 'all-language' ? [] : [languageSelected]
  };

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handleDownload = () => {
    handleCloseDownload();
    tabClipping !== 'cluster'
      ? downloadCsvMediaOnline({
          variables: payloads
        })
      : downloadClusteringCsv({
          variables: {
            ...payloads,
            ...clusteringConfig
          },
          onCompleted: () =>
            handleClickAlert({
              horizontal: 'center',
              vertical: 'top',
              severity: 'success',
              message:
                'It may take some time for the report to be processed.\n The report can be downloaded from the Activity List on the top right corner.'
            }),
          onError(error) {
            handleClickAlert({
              horizontal: 'center',
              vertical: 'top',
              severity: 'error',
              message: 'Failed to download report, please try again.'
            });
            console.log(error.message);
          }
        });
  };

  const handleDownloadWhatsappReport = () => {
    const doc = generateWhatsappReportDoc(articles.data.result);
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `ONM - ${clippingName}.docx`);
    });
    handleCloseDownload();
  };

  const handleDownloadWord = async () => {
    // setIsDownloadingWord(true);
    try {
      const doc = await generateOnmDoc(articles.data.result);
      Packer.toBlob(doc).then((blob) => {
        saveAs(
          blob,
          `${clippingName}-${new Date()
            .toLocaleDateString('id-ID', {
              year: 'numeric',
              month: 'short',
              day: '2-digit'
            })
            .split(' ')
            .join('-')}.docx`
        );
      });
      handleCloseDownload();
    } catch (error) {
      console.log(error);
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'error',
        message: 'Failed to download report, please try again.'
      });
    } finally {
      // setIsDownloadingWord(false);
    }
  };

  const handleDownloadBySentiment = () => {
    const [selectedLabelId, ...rest] = advanceSearchOnlineMedia.labelList;
    const labelName = labels.find(
      (label) => label.id === selectedLabelId
    )?.displayName;
    const doc = generateArticleBySentimentDoc(
      articles.data.result,
      labelName ?? clippingName
    );
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `ONM - ${labelName ?? clippingName}.docx`);
    });
    handleCloseDownload();
  };

  const openDownload = Boolean(anchorElDownload);
  const idPopup = openDownload ? 'simple-popover' : undefined;

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack direction="row" alignItems="center">
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`,
                  mr: theme.spacing(2)
                }}
                size="small"
                onClick={() => navigate(ONLINE_MEDIA_CLIPPING)}
              >
                <ArrowBackIcon />
              </Fab>
              <Typography variant="h1">{clippingName}</Typography>
            </Stack>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<DownloadIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                minWidth: theme.spacing(6),
                height: 36,
                backgroundColor: '#FBFBFB',
                borderRadius: theme.spacing(1)
              }}
              onClick={handleClickDownload}
            >
              Download CSV
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Popover
        id={idPopup}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItemButton
            // disabled={isDownloadingWord}
            onClick={() => {
              handleDownloadWord();
            }}
          >
            <ListItemText primary="Word - Articles" />
          </ListItemButton>
          <ListItemButton onClick={() => handleDownload()}>
            <ListItemText primary="CSV - All Metrics" />
          </ListItemButton>
          <ListItemButton onClick={() => handleDownloadWhatsappReport()}>
            <ListItemText primary="Word - Whatsapp Report" />
          </ListItemButton>
          <ListItemButton onClick={() => handleDownloadBySentiment()}>
            <ListItemText primary="Word - Articles by Label" />
          </ListItemButton>
        </List>
      </Popover>
    </HeaderWrapper>
  );
}

export default HeaderOnlineMediaClippingById;
