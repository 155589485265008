import {
  BorderStyle,
  ITableBordersOptions,
  ITableWidthProperties,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  Document,
  ImageRun,
  HorizontalPosition,
  HorizontalPositionAlign,
  TextRun,
  HeadingLevel,
  ExternalHyperlink
} from 'docx';
import moment from 'moment';
import { IOnlineMediaStreamOutputEntity } from 'src/models/onlineMedia';

const MAX_WIDTH = 300; // Maximum width in document

export async function generateOnmDoc(
  streams: IOnlineMediaStreamOutputEntity[]
) {
  const generateTableDetail = (stream: IOnlineMediaStreamOutputEntity) => {
    const {
      title,
      sourceName,
      writer,
      datePublished,
      prValues,
      sentiment,
      people
    } = stream;

    const borderOptions: ITableBordersOptions = {
      top: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
      bottom: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
      left: { style: BorderStyle.NIL },
      right: { style: BorderStyle.NIL }
    };

    const generateTopPeoples = (people: string[]) => people.join(', ');

    const createTableCell = (
      text: string,
      borderOptions: ITableBordersOptions,
      width: ITableWidthProperties
    ) => {
      return new TableCell({
        borders: borderOptions,
        width: width,
        children: [new Paragraph(text)]
      });
    };

    return new Table({
      borders: {
        insideHorizontal: { style: BorderStyle.NIL },
        insideVertical: { style: BorderStyle.NIL }
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      rows: [
        new TableRow({
          children: [
            createTableCell('Judul', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(title, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Nama Media', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(sourceName, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Penulis', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(writer[0], borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Tanggal', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(
              moment(datePublished).format('llll'),
              borderOptions,
              {
                size: 72,
                type: WidthType.PERCENTAGE
              }
            )
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Sentiment', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(sentiment, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('People', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(generateTopPeoples(people), borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Ad Value', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(`Rp ${prValues.toLocaleString()}`, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        })
      ]
    });
  };
  const generateParagraphs = (content: string) => {
    const paragraphStrings = content.split('\n');
    const paragraphs = paragraphStrings.map((text) => {
      return new Paragraph(text);
    });
    return paragraphs;
  };

  const getImageArrayBuffer = async (
    url: string
  ): Promise<ArrayBuffer | null> => {
    if (!url) return null;
    try {
      const response = await fetch(url);
      console.log(response.headers.get('content-type'));
      if (
        !response.ok ||
        response.type === 'opaque' ||
        response.status === 403
      ) {
        console.warn(`Image fetch failed: ${url}`, {
          status: response.status,
          type: response.type
        });
        return null;
      }

      const arrayBuffer = await response.arrayBuffer();
      if (!arrayBuffer || arrayBuffer.byteLength === 0) {
        console.warn(`Empty image buffer: ${url}`);
        return null;
      }

      return arrayBuffer;
    } catch (error) {
      console.warn(`Error fetching image: ${url}`, error);
      return null;
    }
  };

  const getImageHeight = async (
    imageArrayBuffer: ArrayBuffer
  ): Promise<number> => {
    const blob = new Blob([imageArrayBuffer]);
    const imageUrl = URL.createObjectURL(blob);

    const dimensions = await new Promise<{
      width: number;
      height: number;
    }>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
        URL.revokeObjectURL(imageUrl);
      };
      img.src = imageUrl;
    });

    const aspectRatio = dimensions.height / dimensions.width;
    const scaledHeight = Math.round(MAX_WIDTH * aspectRatio);

    return scaledHeight;
  };

  const generateStreamContent = async (
    stream: IOnlineMediaStreamOutputEntity
  ) => {
    const content = [generateTableDetail(stream)];
    console.log(stream.displayPicture);
    try {
      const imageArrayBuffer = await getImageArrayBuffer(stream.displayPicture);
      if (imageArrayBuffer) {
        const scaledHeight = await getImageHeight(imageArrayBuffer);
        content.push(
          new Paragraph({
            children: [
              new ImageRun({
                data: imageArrayBuffer,
                transformation: {
                  width: MAX_WIDTH,
                  height: scaledHeight
                }
              })
            ]
          })
        );
      } else {
        content.push(
          new Paragraph({
            children: [
              new TextRun({ text: '', break: 1 }),
              new ExternalHyperlink({
                children: [
                  new TextRun({
                    text: stream.displayPicture,
                    style: 'Hyperlink'
                  })
                ],
                link: stream.displayPicture
              })
            ]
          })
        );
      }
    } catch (error) {
      console.warn(`Skipping image for: ${stream.title}`, error);
    }

    // Add the rest of the content
    content.push(
      new Paragraph({
        children: [new TextRun({ text: '', break: 1 })]
      }),
      new Paragraph({
        text: stream.title,
        heading: HeadingLevel.HEADING_1
      }),
      ...generateParagraphs(stream.content),
      new Paragraph({
        children: [new TextRun({ text: '', break: 1 })]
      }),
      new ExternalHyperlink({
        children: [
          new TextRun({
            text: stream.link,
            style: 'Hyperlink'
          })
        ],
        link: stream.link
      }),
      new Paragraph({
        children: [new TextRun({ text: '', break: 2 })]
      })
    );

    return content;
  };

  const streamContents = await Promise.all(
    streams.map((stream) => generateStreamContent(stream))
  );

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: { size: '11pt', font: 'Calibri' },
          paragraph: { spacing: { after: 60, before: 60 } }
        }
      }
    },
    sections: [
      {
        children: streamContents.flat()
      }
    ]
  });

  return doc;
}
